<template>
  <div class="data-box">
    <div class="data-box-head">
      每次餐饮发放仅限一人一份
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-title align-center">
          用餐人员
        </div>
        <div class="body-div-item">
          <div class="span-key">人员姓名</div>
          <div class="span-value">{{personInfo.mealsPerson}}</div>
        </div>
        <div class="body-div-item">
          <div class="span-key span-line">用餐期间</div>
          <div class="span-value">{{ personInfo.mealsCycle }}</div>
        </div>
      </div>
      <div class="body-div" v-show="mealsList.length > 0">
        <div class="body-div-title align-center">
          用餐安排
        </div>
        <div class="body-div-tables">
          <dl>
            <tr>
              <dt>餐点类别</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{["早餐","午餐","晚餐","宵夜"][item.diningType]}}
              </dd>
            </tr>
            <tr>
              <dt>用餐方式</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{["不安排","派餐","堂食","餐补","客户"][item.arrangeType]}}
              </dd>
            </tr>
            <tr>
              <dt>餐标价格</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{'￥' + item.dealNorm}}
              </dd>
            </tr>
          </dl>
        </div>
      </div>
      <div class="body-div" v-show="isSubmit">
        <div class="body-div-title align-center">
          请确认以下发放信息准确
        </div>
        <div class="body-div-item">
          <div class="span-key span-line">用餐时间</div>
          <div class="span-value">{{$tool.dateTimeFormat(new Date())}}</div>
        </div>
        <div class="body-div-item">
          <div class="span-key">领取份数</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.num2"
                placeholder="请输入领取份数"
                maxlength="6"
                type="digit"
            >
              <template #button>
                <span>份</span>
              </template>
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">领取餐食</div>
          <div class="span-input" @click="getMeals">
            <span>{{ sendInfo.mealsName ? sendInfo.mealsName : "请选择领取餐食" }}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">发放人</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.handlePerson"
                placeholder="请输入发放人"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">联系电话</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.handlePhone"
                placeholder="请输入联系电话"
                maxlength="11"
                type="digit"
            >
            </van-field>
          </div>
        </div>
      </div>
      <div class="body-success" v-show="!isSubmit">
        <div class="body-div-success">
          <van-image class="success-image" :src="require('../../assets/image/success.png')"/>
          <div class="success-title">已领取</div>
          <div class="success-time">{{ dateTime }}</div>
        </div>
      </div>
    </div>
    <div class="data-box-foot" v-show="isSubmit">
      <van-button round block type="info" @click="confirmSend">确认发放</van-button>
    </div>
    <van-action-sheet v-model="showMeals" :actions="mealsActions" :description="mealsActions.length > 0 ? '' : '暂无数据'" @select="selectMeals"></van-action-sheet>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  energyWashRoomFoodRegister,
  canteenFoodStatistics
} from "@/api/index";

export default {
  name: "parMeals_send_code",
  data() {
    return {
      personInfo: {
        mealsPerson: "",
        mealsCycle: ""
      },
      showMeals: false,
      mealsActions: [],
      mealsList: [],
      sendInfo: {
        actId: 0,
        activityId: 0,
        handlePerson	: "",
        handlePhone: "",
        hotelDiningId: null,
        roomEnergyFood: "",
        mealsName: "",
        num2: 1,
        type: 4
      },
      dateTime: "",
      isSubmit: true
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let id = that.$route.query.id;
      that.sendInfo.hotelDiningId = that.$route.query.type;
      that.$tool.getActObjInfo(id, (data) => {
        that.sendInfo.actId = data.activityId;
        that.sendInfo.activityId = data.id;
        that.personInfo.mealsPerson = data.chargeName + " " + data.chargePhone;
        that.personInfo.mealsCycle = data.actStartDate + " ~ " + data.actEndDate;
        that.mealsList = data.activityFoodSets ? data.activityFoodSets : [];
      });
      that.getMealsFood();
    },
    getMealsFood(){
      let that = this;
      let date = that.$tool.dateFormat(new Date());
      let params = {
        actId: that.$store.getters.getActivityId,
        startDate: date,
        endDate: date,
        relateType: that.sendInfo.hotelDiningId ,
        companyId: that.$store.getters.getCompanyId,
        pageNum: 1,
        pageSize: 9999,
        type: 3
      };
      canteenFoodStatistics(params).then(res => {
        that.$tool.resValidation(res,() => {
          that.mealsActions = res.data.records;
          if(that.mealsActions.length >= 1) {
            that.sendInfo.mealsName = that.mealsActions[0].str;
            that.sendInfo.roomEnergyFood = that.mealsActions[0].useObjType === 0 || that.mealsActions[0].useObjType === 1 ? that.mealsActions[0].id : that.mealsActions[0].useObjType;
          }
          that.mealsActions.forEach(item => {
            item.name = item.str;
          })
        })
      })
    },
    getMeals(){
      let that = this;
      that.showMeals = true;
    },
    selectMeals(item){
      let that = this;
      that.sendInfo.mealsName = item.name;
      if (item.useObjType == 0 || item.useObjType == 1) {
        that.sendInfo.roomEnergyFood = item.id
      }else {
        that.sendInfo.roomEnergyFood = item.useObjType
      }
      that.showMeals = false;
    },
    confirmSend(){
      let that = this;
      if(that.sendInfo.num2 === ""){
        that.$toast('请输入领取份数！');
        return;
      }
      if(that.sendInfo.roomEnergyFood === ""){
        that.$toast('请选择领取餐食！');
        return;
      }
      if(that.sendInfo.handlePerson === ""){
        that.$toast('请输入发放人姓名！');
        return;
      }
      if(that.sendInfo.handlePhone === ""){
        that.$toast('请输入发放人联系电话！');
        return;
      }
      energyWashRoomFoodRegister(that.sendInfo).then(res => {
        that.$tool.resValidation(res,() => {
          that.isSubmit = false;
          that.dateTime = that.$tool.dateTimeFormat(new Date());
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.body-div-tables{
  margin: 10px 0px;
  border-radius: 10px;
  border: 1px solid #C1D5FF;
  font-size: 12px;
  dl{
    border-radius: 10px;
    margin: 0px;
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      dt {
        width: 20%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #f0f5ff;
        color: #8B8FAD;
        border-top: 1px dotted #C1D5FF;
      }
      dd {
        width: 20%;
        height: 40px;
        margin: 0px;
        line-height: 40px;
        text-align: center;
        border-left: 1px dotted #C1D5FF;
        border-top: 1px dotted #C1D5FF;
      }
    }
    tr:first-child{
      dt {
        border-top-left-radius: 10px;
        border-top: none;
      }
      dd {
        border-top: none;
      }
    }
    tr:last-child{
      dt {
        border-bottom-left-radius: 10px;
      }
    }
  }
}
</style>